
import { defineComponent, ref, onMounted } from "vue";
import avancement from "./avancement.vue";
import {
  IProjet,
  IWorks,
  IWork_lines,
  IQuestions,
  IReponses,
} from "../interfaces/project";
import { FormatDate } from "../utils/functions";
import axios from "axios";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "Panel",
  components: {
    avancement,
  },

  props: {
    projets: {
      type: Object,
      required: true,
    },
    idProj: {
      type: String,
      required: true,
    },
    idUser: {
      type: String,
      required: true
    },
    isAdmin: {
      type: Boolean,
      required: false
    }
  },

  setup(props) {
    //const projet: Object = props.projets

    const { cookies } = useCookies();

    let lstWork = ref<IWorks[]>([]);
    let lstLine = ref<IWork_lines[]>([]);
    let lstQuestion = ref<IQuestions[]>([]);
    let quest = ref('')
    let msgErr = ref('')
    const store = useStore();
    const router = useRouter()
    let tmpProjet = ref<any>({})
    let AffBarre = ref(false)
    let token = cookies.get('AtomProgress_JWT')

    onMounted(() => {
      if(props.idProj == '-1') {
        if (store.state.apercuProj[0] != '-1') {
          let id:string = store.state.apercuProj[0]
          tmpProjet.value = store.state.apercuProj[2]
          ListWorks(id)
          ListLines(id)
          ListQuestions(id)
          AffBarre.value=false
        }
        
      } else {
        if (props.idProj != undefined && props.idProj != '' ) {
          tmpProjet.value = props.projets
          ListWorks(props.idProj)
          ListLines(props.idProj)
          ListQuestions(props.idProj)
          AffBarre.value=true
        }
      }
     
      
    });

    const FD = FormatDate;

    

    /* const reponses: Array<IReponses> = [
      {
        id: "001",
        idquestion: "001",
        question: "vfv v frv e ve vfvdvfv",
      },
      {
        id: "002",
        idquestion: "002",
        question: "2222 fgr ger greggrg re gr",
      },
    ]; */
    let poseQ = ref(false);
    const affPoseQ = () => {
      poseQ.value ? (poseQ.value = false) : (poseQ.value = true);
    };

    const ListWorks = async (id: string) => {
      if (id == undefined || id == '') return

      axios({
        method: "get",
        url: store.state.server_url + "/api/work",
        params: {
          idproject: id,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstWork.value = response.data.lst;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const ListLines = async (id: string) => {
      if (id == undefined || id == '') return
      
      axios({
        method: "get",
        url: store.state.server_url + "/api/workLine/all",
        params: {
          idproject: id,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstLine.value = response.data.lst;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const ListQuestions = async (id: string) => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/project/question",
        params: {
          idproject: id,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstQuestion.value = response.data.lst;
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const CreateQuestion = () => {
      msgErr.value=''
      if (quest.value === '') {
        msgErr.value = "Vous devez saisir une question"
        return
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/project/question",
        data: {
          idproject: props.idProj,
          iduser: props.idUser,
          question: quest.value,
          codeEnt: cookies.get("jwtAtomProgress_codeEnt")
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response) {
            ListQuestions(props.idProj)
            quest.value=''
            poseQ.value=false
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const OpenProject = (id: string) => {
        //console.log(store.state.current_project)
        store.commit('changeCurrentProject',id)
       // console.log(id,store.state.current_project)
        router.push('/CreateProject')
    }

    const AffArbo = (id: string) => {
      store.commit('change_IdArboProject',id)
        //console.log(id,store.state.current_project)
        store.commit('changeRetourListe', 'liste')
        router.push('/ArboProject')
    }

    return {
      affPoseQ,
      poseQ,
      FD,
      lstWork,
      lstLine,
      lstQuestion,
      CreateQuestion,
      quest,
      msgErr,
      OpenProject,
      tmpProjet,
      AffBarre,
      AffArbo,
    };
  },
});
