
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { IProjet } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "ListeProject",
  components: {
    HeadBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter()
    const { cookies } = useCookies();

    let lstProjet = ref<IProjet[]>([]);
    let lstUser = ref<IUser[]>([]);
    let msgErr = ref("");
    let msgSuccess = ref('')
    let affDeleteProj = ref(-1)
    let modeAff = ref('no')
    const FD = FormatDate;
    let idUser = ref('All')
    let codeEnt = cookies.get('jwtAtomProgress_codeEnt')
    let token = cookies.get('AtomProgress_JWT')

    onMounted(() => {
      readProjects();
      ListUsers();
    });

    const ListUsers = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          lstUser.value = response.data.lst;
          console.log(lstUser.value)
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });
    };

    const readProjects = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/project/all",
        params: {
          iduser: idUser.value,
          search: modeAff.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstProjet.value = response.data.lst;
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
        });
    };

    const RecupUser = (id: string) => {
      let client = "";
      lstUser.value.forEach((element) => {
        if (element._id === id) {
          //console.log(element._id, id);
          client = element.login + " (" + element.email + ")";
        }
      });
      return client;
    };

    const OpenProject = (id: string) => {
        //console.log(store.state.current_project)
        store.commit('changeCurrentProject',id)
        //console.log(id,store.state.current_project)
        store.commit('changeRetourListe', 'liste')
        router.push('/CreateProject')
    }

    const deleteProject = (id: string) => {
      axios({
        method: "delete",
        url: store.state.server_url + "/api/project",
        params: {
          idproject: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then(() => {
          msgSuccess.value = 'Projet supprimé avec succés.'
          setTimeout(() => {
            msgSuccess.value = ''
            readProjects()
          }, 1500)
           
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la suppression du projet !";
          console.log(err);
        });
    }

    const AffArbo = (id: string) => {
      store.commit('change_IdArboProject',id)
        //console.log(id,store.state.current_project)
        store.commit('changeRetourListe', 'liste')
        router.push('/ArboProject')
    }

    return {
      lstProjet,
      msgErr,
      RecupUser,
      FD,
      OpenProject,
      affDeleteProj,
      modeAff,
      readProjects,
      deleteProject,
      msgSuccess,
      lstUser,
      idUser,
      AffArbo,
    };
  },
});
