
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { IWorks, IWork_lines } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions"
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "CreateUser",
  components: {
    HeadBar,
  },

  setup() {
    let nbProjet = ref(0);
    let nbTermine = ref(0);

    //let idProject = ref("6267ae1ede2ee1cd2cb1383b");
    let idProject = ref("");
    let idUserProject = ref("");

    const store = useStore();
    const { cookies } = useCookies();
    const router = useRouter();
    const FD = FormatDate;

    //**************************   ENTETE DU PROJET ***************************/

    let iduser = ref("");
    let proj_Des = ref("");
    let proj_state = ref("");
    //let proj_arrive = ref(new Date());
    //let proj_livraison = ref(new Date());
    let proj_arrive = ref("");
    let proj_livraison = ref("");
    let proj_comment = ref("");
    let proj_remarque = ref("");
    //let searchEmail = ref("");
    //let searchLogin = ref("");
    let listeUser = ref<IUser[]>([]);
    let msgConfirm = ref("");
    let labelCreeTravaux = ref("Créer un travail");
    let labelCreeLigne = ref("Créer une ligne de détail");
    let client = ref("");
    let idWorkToModif = ref("");
    let idLineToModif = ref("");
    let affDeleteLine = ref(-1)
    let affDeleteWork = ref(-1)
    let dateaffArrive = ref("")
    let dateaffLiv = ref("")
    let msgTermine = ref("Marquer comme terminé")
    let codeEnt = cookies.get('jwtAtomProgress_codeEnt')
    let token = cookies.get('AtomProgress_JWT')
    let ligne_arrive = ref('')
    let ligne_tps = ref(0)

    let msgErr = ref("");
    let msgSuccess = ref("");

    onMounted(() => {
      //console.log(liste);
      //read all the users
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          listeUser.value = response.data.lst;
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });

      idProject.value = store.state.current_project;
      console.log("idP: " + idProject.value);

      if (idProject.value != "") {
        readProject(idProject.value);
        ListWorks(idProject.value);
      }
    });

    const dateLoad = (d: Date) => {
      let d1: string = FormatDate(d,true);
      console.log(d);
      d1 = d1.split("T")[0];
      console.log(d1);
      let tabD = d1.split("/");
      console.log(tabD);
      d1 = tabD[2] + "-" + tabD[1] + "-" + tabD[0];
      console.log(d1);
      return d1;
    }

    const OpenListeProject = () => {
      let retour = store.state.retourListe
      if (retour == 'todo') {
        router.push("/Planning");
      } else {
        router.push("/ListeProject");
      }
      
    }

    const readProject = (id: string) => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/project",
        params: {
          idproject: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            console.log(response.data.lst);

            proj_Des.value = response.data.lst[0].designation;
            idUserProject.value = response.data.lst[0].id_user;
            proj_state.value = response.data.lst[0].state;
            proj_arrive.value = dateLoad(response.data.lst[0].date_arrivee);
            proj_livraison.value = dateLoad(response.data.lst[0].date_liv);
            console.log("DA: " + dateaffArrive.value)
            proj_comment.value = response.data.lst[0].commentaire;
            proj_remarque.value = response.data.lst[0].remarque;
            client.value = response.data.lst[0].client;
            if (response.data.lst[0].termine == true) {
              msgTermine.value = "Marquer comme non terminé"
            } else {
              msgTermine.value = "Marquer comme terminé"
            }
          }
        })
        .catch((err) => {
          msgErr.value = "Une erreur est survenue durant la lecture (projet) !";
          console.log(err);
        });
    };

    

    const createProject = async () => {
      
      msgErr.value = "";
      msgSuccess.value = "";

      let cl = document.getElementById("client") as HTMLSelectElement;
      if (cl) {
        console.log(cl.options[cl.selectedIndex].text);
        client.value = cl.options[cl.selectedIndex].text;
      }

      if (
        iduser.value === "" ||
        proj_Des.value === "" ||
        proj_state.value === ""
      ) {
        msgErr.value = "Le client, la désignation et l'état sont obligatoires.";

        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/project",
        data: {
          iduser: iduser.value,
          designation: proj_Des.value,
          state: proj_state.value,
          commentaire: proj_comment.value,
          date_arrivee: proj_arrive.value,
          date_liv: proj_livraison.value,
          remarque: proj_remarque.value,
          client: client.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          msgSuccess.value = "projet créé avec succés";
          /* iduser.value = "";
          proj_Des.value = "";
          proj_state.value = "";
          proj_remarque.value = "";
          proj_arrive.value = new Date();
          proj_livraison.value = new Date();
          proj_comment.value = ""; */

          setTimeout(() => {
            msgSuccess.value = "";
            idProject.value = response.data.projectId;
            idUserProject.value = response.data.idUser;
          }, 1500);
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant l'enregistrement (projet) !";
          console.log(err);
        });
    };

    const modifProject = async (id: string) => {
      msgErr.value = "";
      msgSuccess.value = "";

      console.log('id: ' + id);
      

      if (
        proj_Des.value === "" ||
        proj_state.value === ""
      ) {
        msgErr.value = "La désignation et l'état sont obligatoires.";

        return;
      }

      axios({
        method: "patch",
        url: store.state.server_url + "/api/project",
        data: {
          idproject: id,
          designation: proj_Des.value,
          state: proj_state.value,
          commentaire: proj_comment.value,
          date_arrivee: proj_arrive.value,
          date_liv: proj_livraison.value,
          remarque: proj_remarque.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then(() => {
          msgSuccess.value = "Entête modifié avec succés";
          /* iduser.value = "";
          proj_Des.value = "";
          proj_state.value = "";
          proj_remarque.value = "";
          proj_arrive.value = new Date();
          proj_livraison.value = new Date();
          proj_comment.value = ""; */

          setTimeout(() => {
            msgSuccess.value = "";
          }, 1500);
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la modification (projet) !";
          console.log(err);
        });
    };

    const modifFinish = async () => {
      msgErr.value = "";
      msgSuccess.value = "";
      let id = idProject.value
      let finish = false
      if (msgTermine.value == 'Marquer comme terminé') {
        finish = true        
      } else {
        finish = false
      }

      axios({
        method: "patch",
        url: store.state.server_url + "/api/project/finish",
        data: {
          id: id,
          finish: finish,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then(() => {
          if (finish == true) {
            msgSuccess.value = "Projet marquer comme terminé";
            msgTermine.value='Marquer comme non terminé'
          } else {
            msgSuccess.value = "Projet marquer comme non terminé";
            msgTermine.value='Marquer comme terminé'
          }
          
          /* iduser.value = "";
          proj_Des.value = "";
          proj_state.value = "";
          proj_remarque.value = "";
          proj_arrive.value = new Date();
          proj_livraison.value = new Date();
          proj_comment.value = ""; */

          setTimeout(() => {
            msgSuccess.value = "";
          }, 1500);
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la modification (projet) !";
          console.log(err);
        });
    };

    //**************************   GESTION DES TRAVAUX ***************************/
    let idWork = ref("");
    let currentWork = ref("");
    let work_des = ref("");
    let work_comment = ref("");
    let lstTravaux = ref<IWorks[]>([]);
    let AffSaisieTarvaux = ref(false);

    const createWork = async () => {
      msgErr.value = "";
      msgSuccess.value = "";

      if (idProject.value === "" || work_des.value === "") {
        msgErr.value = "La désignation, et le Projet sont obligatoires.";

        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/work",
        data: {
          idproject: idProject.value,
          idUser: idUserProject.value,
          designation: work_des.value,
          commentaire: work_comment.value,
          idWorkModif: idWorkToModif.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //msgSuccess.value = "projet créé avec succés";
          /* iduser.value = "";
          proj_Des.value = "";
          proj_state.value = "";
          proj_remarque.value = "";
          proj_arrive.value = new Date();
          proj_livraison.value = new Date();
          proj_comment.value = ""; */
          if (response.data._id != "") ListWorks(idProject.value);
          AffSaisieTarvaux.value = false;
          labelCreeTravaux.value = "Créer un travail";
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant l'enregistrement (work) !";
          console.log(err);
        });
    };

    const ListWorks = (id: string) => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/work",
        params: {
          idproject: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstTravaux.value = response.data.lst;
            console.log(lstTravaux.value);
          }
        })
        .catch((err) => {
          msgErr.value = "Une erreur est survenue durant la lecture (work) !";
          console.log(err);
        });
    };

    const ChargeWork = (id: string, data: ["", ""]) => {
      idWorkToModif.value = id;
      work_des.value = data[0];
      work_comment.value = data[1];

      AffSaisieTarvaux.value = true;
      labelCreeTravaux.value = "Annuler";
    };

    const ClickOnWork = (id: string) => {
      //const wid:string = id
      const w1 = document.getElementById(id);
      if (w1) {
        const w2 = w1.innerHTML;

        idWork.value = id;
        currentWork.value = w2;
        ListLines(idWork.value);
      }
    };

    const deleteWork = (id: string) => {
      affDeleteLine.value = -1
      axios({
        method: "delete",
        url: store.state.server_url + "/api/work",
        params: {
          id: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data._id != "") {
            ListWorks(idProject.value);
            lstLine.value = []
            idWork.value = ''
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la suppression (workLine) !";
          console.log(err);
        });
    }


    //**************************   GESTION DES LINE DE DETAIL ***************************/
    let AffSaisieLine = ref(false);
    let idLine = ref("");
    let Line_des = ref("");
    let Line_comment = ref("");
    let Line_progress = ref(0);
    let lstLine = ref<IWork_lines[]>([]);

    const creeNouvelleLigne = () => {
      Line_des.value = ''
      Line_comment.value = ''
      Line_progress.value = 0
      ligne_arrive.value = proj_livraison.value
      ligne_tps.value = 1
      console.log(proj_arrive.value);
      
    }

    const chargeLine = (id: string, data: ['','',0,'',0]) => {

      idLineToModif.value = id
      Line_des.value = data[0]
      Line_comment.value = data[1]
      Line_progress.value = data[2]
      AffSaisieLine.value = true
      labelCreeLigne.value = 'Annuler';
      ligne_arrive.value = data[3]
      ligne_tps.value = data[4]
    }

    const createWorkLine = async () => {
      msgErr.value = "";
      msgSuccess.value = "";

      if (
        idProject.value === "" ||
        Line_des.value === "" ||
        idWork.value === ""
      ) {
        msgErr.value =
          "La désignation, le travail et le Projet sont obligatoires.";

        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/workLine",
        data: {
          idproject: idProject.value,
          idwork: idWork.value,
          idUser: idUserProject.value,
          designation: Line_des.value,
          commentaire: Line_comment.value,
          progress: Line_progress.value,
          idLineModif: idLineToModif.value,
          codeEnt: codeEnt,
          ligne_arrive: ligne_arrive.value,
          ligne_tps: ligne_tps.value,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //msgSuccess.value = "projet créé avec succés";
          /* iduser.value = "";
          proj_Des.value = "";
          proj_state.value = "";
          proj_remarque.value = "";
          proj_arrive.value = new Date();
          proj_livraison.value = new Date();
          proj_comment.value = ""; */
          if (response.data._id != "") ListLines(idWork.value);
          AffSaisieLine.value = false;
          labelCreeLigne.value = "Créer une ligne de détail";
          idLineToModif.value = ''
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant l'enregistrement (workLine) !";
          console.log(err);
        });
    };

    const ListLines = (id: string) => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/workLine",
        params: {
          idwork: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstLine.value = response.data.lst;
            console.log(lstLine.value);
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (workLine) !";
          console.log(err);
        });
    };

    const deleteLine = (id: string) => {
      affDeleteLine.value = -1
      axios({
        method: "delete",
        url: store.state.server_url + "/api/workLine",
        params: {
          id: id,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data._id != "") ListLines(idWork.value);
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la suppression (workLine) !";
          console.log(err);
        });
    }

    
    return {
      //*****  project  *****
      idProject,
      nbProjet,
      nbTermine,
      iduser,
      proj_Des,
      proj_state,
      proj_arrive,
      store,
      createProject,
      modifProject,
      readProject,
      proj_livraison,
      proj_comment,
      proj_remarque,
      //*****  work  *****
      idWork,
      currentWork,
      work_des,
      work_comment,
      createWork,
      ListWorks,
      ClickOnWork,
      lstTravaux,
      AffSaisieTarvaux,
      //*****  work_lines  *****
      idLine,
      Line_des,
      Line_comment,
      Line_progress,
      AffSaisieLine,
      lstLine,
      createWorkLine,
      ListLines,
      //*****  common  *****
      msgErr,
      msgSuccess,
      listeUser,
      msgConfirm,
      labelCreeTravaux,
      labelCreeLigne,
      client,
      ChargeWork,
      idWorkToModif,
      chargeLine,
      idLineToModif,
      deleteLine,
      affDeleteLine,
      affDeleteWork,
      deleteWork,
      dateLoad,
      dateaffArrive,
      dateaffLiv,
      msgTermine,
      OpenListeProject,
      modifFinish,
      ligne_arrive,
      ligne_tps,
      FD,
      creeNouvelleLigne,
    };
  },
});
