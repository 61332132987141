
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { IProjet, IWorks, IWork_lines } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import avancement from "../../components/avancement.vue";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "ArboProject",
  components: {
    HeadBar,
    avancement,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { cookies } = useCookies();

    let lstQuestions = ref<IProjet[]>([]);
    let lstUser = ref<IUser[]>([]);
    let msgErr = ref("");
    let msgSuccess = ref("");
    let modeAff = ref("0");
    let msgReponse = ref("");
    let codeEnt = cookies.get("jwtAtomProgress_codeEnt");
    let token = cookies.get("AtomProgress_JWT");
    let idProject = ref("");
    let desProj = ref("");
    let clientProj = ref("");
    let datelivProj = ref("");
    let lstWorks = ref<IWorks[]>([]);
    let lstLines = ref<IWork_lines[]>([]);
    let TotHeureProj = ref(0);
    let PourcProj = ref(0);
    let TotHeureFait = ref(0);
    let TotHeureReste = ref(0);
    let datePlustot = ref(new Date());
    let modifLigne = ref({
      id: "",
      des: "",
      progress: 0,
      tpsPrevu: 1,
      tpsPasse: 0,
    });
    let TotTpsPasse = ref(0)

    const FD = FormatDate;

    onMounted(() => {
      idProject.value = store.state.idArboProject;
      //console.log("idP: " + idProject);

      ListeArbo();
      ListUsers();
    });

    onUpdated(() => {
      TotalParWork();
    });

    const ListUsers = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          //console.log(response.data.lst)
          lstUser.value = response.data.lst;
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });
    };

    const ListeArbo = () => {
      msgErr.value = "";
      msgSuccess.value = "";

      TotHeureProj.value = 0;
      PourcProj.value = 0;
      TotHeureFait.value = 0;
      TotHeureReste.value = 0;
      TotTpsPasse.value = 0

      axios({
        method: "get",
        url: store.state.server_url + "/api/arbo",
        params: {
          idproject: idProject.value,
          codeEnt: codeEnt,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lstProjet != []) {
            desProj.value = response.data.lstProjet[0].designation;
            clientProj.value = response.data.lstProjet[0].client;
            datelivProj.value = response.data.lstProjet[0].date_liv;

            lstWorks.value = response.data.lstWorks;
            lstLines.value = response.data.lstLines;

            TotalHeure();
            //console.log(TotHeureProj.value);

            if (TotHeureProj.value > 0) {
              PourcProj.value = (PourcProj.value * 100) / TotHeureProj.value;
            } else {
              PourcProj.value = 0;
            }

            TotHeureReste.value = TotHeureProj.value - TotHeureFait.value;

            if (TotHeureReste.value < 0) TotHeureReste.value = 0;

            let WE = 0;
            WE = TotHeureReste.value / 5;
            WE = parseInt(WE.toString(), 10);
            WE = WE * 2 + 1;

            console.log("WE: " + WE);

            datePlustot.value.setDate(
              datePlustot.value.getDate() + TotHeureReste.value + WE
            );

            TotalParWork();

            if (PourcProj.value >= 100) {
              let proj = document.getElementById("projet");
              if (proj) {
                proj.style.backgroundColor = "green";
              }
            }
          } else {
            msgErr.value =
              "Le projet doit contenir au moins un travail et une ligne de détail pour être affiché sous forme d'arborescence";
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
        });
    };

    const TotalHeure = () => {
      //lstLines.value.forEach(element => TotHeureProj.value += parseFloat(element.tpsPrevu.toString()))
      lstLines.value.forEach((element) => {
        let t = 0;
        if (element.tpsPrevu > 0) {
          t = parseFloat(element.tpsPrevu.toString());
        } else {
          t = 1;
        }
        TotHeureProj.value += t;

        let p = 0;
        if (element.progress > 0) p = parseFloat(element.progress.toString());

        PourcProj.value += t * (p / 100);
        TotHeureFait.value = PourcProj.value;

        if (element.tpsReel != undefined) TotTpsPasse.value += parseFloat(element.tpsReel.toString())
      });
    };

    const TotalParWork = () => {
      
      lstWorks.value.forEach((element, i) => {
        let id = element._id;
        let progres = 0;
        let tot = 0;
        lstLines.value.forEach((el) => {
          if (el.idwork == id) {
            let t = 0;

            if (el.tpsPrevu > 0) {
              t = parseFloat(el.tpsPrevu.toString());
            } else {
              t = 1;
            }
            tot += t;

            let p = 0;
            if (el.progress > 0) p = parseFloat(el.progress.toString());

            progres += t * (p / 100);
            console.log(t, p, progres);
          }
          
        });

        //console.log(lstWorks.value[i]);
        if (tot > 0) progres = (progres * 100) / tot;
        lstWorks.value[i].progress = progres;
        lstWorks.value[i].nbJours = tot;

        if (progres >= 100) {
          let wId = "W_" + id;
          console.log(wId);

          let w = document.getElementById(wId);
          console.log(w);
          if (w) {
            w.style.background = "green";
            console.log(w);
          }
        }
      });
    };

    const RecupUser = (id: string) => {
      let client = "";
      lstUser.value.forEach((element) => {
        if (element._id === id) {
          client = element.login + " (" + element.email + ")";
        }
      });
      return client;
    };

    const OpenProject = (id: string) => {
      console.log(store.state.current_project);
      store.commit("changeCurrentProject", id);
      console.log(id, store.state.current_project);
      router.push("/CreateProject");
    };

    const AffmodifL = (id: string) => {
      for (let i = 0; i < lstLines.value.length; i++) {
        let id1 = lstLines.value[i]._id;
        if (id1.toString() == id) {
          modifLigne.value.id = id;
          modifLigne.value.des = lstLines.value[i].designation.toString();

          modifLigne.value.progress=0
          if (lstLines.value[i].progress != undefined)
          modifLigne.value.progress = parseInt(
            lstLines.value[i].progress.toString(),
            10
          );
          modifLigne.value.tpsPrevu=0
          if (lstLines.value[i].tpsPrevu != undefined)
          modifLigne.value.tpsPrevu = parseFloat(
            lstLines.value[i].tpsPrevu.toString()
          );
          modifLigne.value.tpsPasse = 0
          if (lstLines.value[i].tpsReel != undefined)
          modifLigne.value.tpsPasse = parseFloat(
            lstLines.value[i].tpsReel.toString()
          );

          let topS = parseInt(window.scrollY.toString()); //document.getElementById(line)
          console.log(topS);

          let top = 0;
          if (topS) {
            top = topS + window.innerHeight / 2 - 200;
          } else {
            top = window.innerHeight / 2 - 200;
          }
          console.log(top);
          const apercu = document.getElementById("modifL");
          if (apercu) {
            apercu.style.opacity = "1";
            apercu.style.top = top + "px";
            //apercu.style.left = '0px'
            //apercu.style.right = '0px'
          }
          const arb = document.getElementById("arbo")
          if (arb) {
            arb.classList.add('blurDiv')
          }
        }
      }
    };

    const CacheModifL = () => {
      const apercu = document.getElementById("modifL");
      if (apercu) {
        apercu.style.opacity = "0";
        apercu.style.top = "-400px";
        //apercu.style.left = '0px'
        //apercu.style.right = '0px'
      }
      const arb = document.getElementById("arbo")
          if (arb) {
            arb.classList.remove('blurDiv')
          }
    };

    const modifL = () => {
      msgErr.value = "";
      axios({
        method: "patch",
        url: store.state.server_url + "/api/workLine/arbo",
        data: {
          id: modifLigne.value.id,
          progress: modifLigne.value.progress,
          tpsPrevu: modifLigne.value.tpsPrevu,
          tpsPasse: modifLigne.value.tpsPasse,
          codeEnt: codeEnt,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          ListeArbo();
          CacheModifL();
        })
        .catch((err) => {
          msgErr.value = "Erreur durant la mise à jour de la ligne !";
          console.log(err);
        });
    };

    const printDiv = () => {
      window.print()
      /* var divToPrint = document.getElementById("arbo");
      var newWin = window.open("", "Print-Window", "width=100%,top=30,left=10");
      if (newWin && divToPrint) {
        newWin.document.open();
        newWin.document.write(
          '<html><body   onload="window.print()">' +
            divToPrint.innerHTML +
            "</body></html>"
        );
        newWin.document.close();
        //setTimeout(function(){newWin.close();},10);
      } */
    };

    return {
      idProject,
      lstQuestions,
      msgErr,
      RecupUser,
      FD,
      OpenProject,
      modeAff,
      ListeArbo,
      msgSuccess,
      desProj,
      clientProj,
      datelivProj,
      lstWorks,
      lstLines,
      TotHeureProj,
      PourcProj,
      TotHeureReste,
      TotHeureFait,
      datePlustot,
      modifLigne,
      AffmodifL,
      CacheModifL,
      modifL,
      printDiv,
      TotTpsPasse,
    };
  },
});
