
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { IProjet } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "ListeQuestion",
  components: {
    HeadBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter()
    const { cookies } = useCookies();

    let lstQuestions = ref<IProjet[]>([]);
    let lstUser = ref<IUser[]>([]);
    let msgErr = ref("");
    let msgSuccess = ref("")
    let modeAff = ref('0')
    let msgReponse = ref('')
    let codeEnt = cookies.get('jwtAtomProgress_codeEnt')
    let token = cookies.get('AtomProgress_JWT')

    const FD = FormatDate;

    onMounted(() => {
      ListeQuestions();
      ListUsers();
    });

    const ListUsers = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          lstUser.value = response.data.lst;
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });
    };

    const ListeQuestions = () => {
      msgErr.value = ''
      msgSuccess.value = ''

      axios({
        method: "get",
        url: store.state.server_url + "/api/project/question",
        params: {
          idproject: "All",
          mode: modeAff.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstQuestions.value = response.data.lst;
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
        });
    };

    const RecupUser = (id: string) => {
      let client = "";
      lstUser.value.forEach((element) => {
        if (element._id === id) {
          client = element.login + " (" + element.email + ")";
        }
      });
      return client;
    };

    const PostReponse = (idproj: string, idquest: string) => {
      msgErr.value=''
      msgSuccess.value = ''

      let rep1 = ''//(<HTMLInputElement>document.getElementById('rep' + idquest)).value
      const txtRep = (document.getElementById('rep' + idquest) as HTMLTextAreaElement)
      console.log(idquest, txtRep)
      if (txtRep) {
        rep1 = txtRep.value
      } 
       
      /* if (rep1 === '') {
        msgErr.value = "Vous devez saisir une Réponse"
        return
      } */

      axios({
        method: "post",
        url: store.state.server_url + "/api/project/reponse",
        data: {
          idproject: idproj,
          idquestion: idquest,
          reponse: rep1,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          msgSuccess.value = 'Réponse postée avec succés'
          setTimeout(() => {
            msgSuccess.value = ''
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const OpenProject = (id: string) => {
        console.log(store.state.current_project)
        store.commit('changeCurrentProject',id)
        console.log(id,store.state.current_project)
        router.push('/CreateProject')
    }

    return {
      lstQuestions,
      msgErr,
      RecupUser,
      FD,
      OpenProject,
      modeAff,
      PostReponse,
      ListeQuestions,
      msgSuccess,
    };
  },
});
