
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IProjet, IWorks, IWork_lines, IToDo } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "ToDoProject",
  components: {
    HeadBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { cookies } = useCookies();

    let msgErr = ref("");
    let msgSuccess = ref("");
    let modeAff = ref("0");
    let codeEnt = cookies.get("jwtAtomProgress_codeEnt");
    let token = cookies.get("AtomProgress_JWT");
    let idProject = ref("");
    let lstToDo = ref<IToDo[]>([]);
    let createVisible = ref(false);
    let createState = ref(-1);
    let txtState = ref("");
    let designation = ref("");
    let detail = ref("");
    let priorite = ref(1);

    let lstProp = ref<any[]>([]);
    let lstAFaire = ref<any[]>([]);
    let lstEnCours = ref<any[]>([]);
    let lstTermine = ref<any[]>([]);
    let affDeleteTache = ref(-1)
    let enCharge = ref(false);
    const FD = FormatDate;

    onMounted(() => {
      idProject.value = store.state.idToDoProject;
      //console.log("idP: " + idProject);

      ListeToDo();
    });

    const ListeToDo = () => {
      msgErr.value = "";
      msgSuccess.value = "";
      enCharge.value = true;

      axios({
        method: "get",
        url: store.state.server_url + "/api/ToDo",
        params: {
          idproject: idProject.value,
          codeEnt: codeEnt,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lst != []) {
            //console.log(TotHeureProj.value);
            lstToDo.value = response.data.lst;

            lstProp.value = [];
            lstAFaire.value = [];
            lstEnCours.value = [];
            lstTermine.value = [];

            lstToDo.value.forEach((t) => {
              if (t.state != undefined) {
                let etat: number = parseInt(t.state.toString());
                console.log(etat);

                switch (etat) {
                  case 1:
                    lstProp.value.push(t);
                    break;
                  case 2:
                    lstAFaire.value.push(t);
                    break;
                  case 3:
                    lstEnCours.value.push(t);
                    break;
                  case 4:
                    lstTermine.value.push(t);
                    break;
                }
              }
            });

            enCharge.value = false;
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
          enCharge.value = false;
        });
    };

    const ajoutTache = (state: number) => {
      switch (state) {
        case 1:
          txtState.value = "Nouvelle Proposition";
          break;
        case 2:
          txtState.value = "Nouvelle Tâche à faire";
          break;
        case 3:
          txtState.value = "Nouvelle Tâche en cours";
          break;
        case 4:
          txtState.value = "Nouvelle Tâche terminée";
          break;
      }
      designation.value = '';
      detail.value = '';
      priorite.value = 1
      createState.value = state;
      createVisible.value = true;
    };

    const createTache = () => {
      msgErr.value = "";
      enCharge.value = true;

      if (designation.value == "" || designation.value == undefined) {
        msgErr.value = "La désignation est obligatoire.";
        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/ToDo",
        data: {
          idproject: idProject.value,
          codeEnt: codeEnt,
          designation: designation.value,
          detail: detail.value,
          priorite: priorite.value,
          state: createState.value,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lstProjet != []) {
            //console.log(TotHeureProj.value);
            createVisible.value = false;
            ListeToDo();
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
          enCharge.value = false;
        });
    };

    const ModifState = (id: string, state: number) => {
      msgErr.value = "";
      enCharge.value = true;

      axios({
        method: "patch",
        url: store.state.server_url + "/api/ToDo/state",
        data: {
          id: id,
          codeEnt: codeEnt,
          state: state,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lstProjet != []) {
            //console.log(TotHeureProj.value);
            ListeToDo();
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
          enCharge.value = false;
        });
    };

    const ModifDetail = (id: string) => {
      msgErr.value = "";
      enCharge.value = true;

      let detail = '';
      let det = (document.getElementById('detail_' + id) as HTMLTextAreaElement);
      if (det) {
        detail = det.value
      } else {
        return
      }

      axios({
        method: "patch",
        url: store.state.server_url + "/api/ToDo/detail",
        data: {
          id: id,
          codeEnt: codeEnt,
          detail: detail,
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lstProjet != []) {
            //console.log(TotHeureProj.value);
            ListeToDo();
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
          enCharge.value = false;
        });
    };

    const DeleteTodo = (id: string) => {
      msgErr.value = "";
      enCharge.value = true;

      axios({
        method: "delete",
        url: store.state.server_url + "/api/ToDo",
        data: {
          id: id,
          
        },
        headers: {
          authorization: token,
        },
      })
        .then((response) => {
          if (response.data.lstProjet != []) {
            //console.log(TotHeureProj.value);
            ListeToDo();
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) !";
          console.log(err);
          enCharge.value = false;
        });
    };

    return {
      idProject,
      msgErr,
      FD,
      modeAff,
      ListeToDo,
      msgSuccess,
      createVisible,
      createState,
      txtState,
      ajoutTache,
      designation,
      detail,
      createTache,
      priorite,
      lstProp,
      lstAFaire,
      lstEnCours,
      lstTermine,
      ModifState,
      affDeleteTache,
      ModifDetail,
      DeleteTodo,
      enCharge,
    };
  },
});
