import axios from 'axios'
import { useStore } from "vuex";

const store = useStore()

export function FormatDate(d1: Date, SansHeure?: boolean):string {
    if (d1 != undefined) {
      const D1 = new Date(d1);
      const D = D1.toLocaleDateString();
      const T = D1.toLocaleTimeString();
      if (SansHeure) {
        return D 
      } else {
        return D + " à " + T;
      }
      
    } else {
      const D2 = new Date();
      //D2 = D2.toLocaleString();
      const D3: string = D2.toLocaleString()
      return D3;
    }
  }

  /* const readProject = (id:string) => {
    axios({
      method: "get",
      url: store.state.server_url + "/api/project",
      params: {
        idproject: id,
      },
    })
      .then((response) => {
        if (response.data.lst != []) {
          console.log(response.data.lst);
          
          proj_Des.value = response.data.lst[0].designation
          iduser.value = response.data.lst[0].id_user
          proj_state.value = response.data.lst[0].state
          proj_arrive.value = response.data.lst[0].date_arrivee
          proj_livraison.value = response.data.lst[0].date_liv
          proj_comment.value = response.data.lst[0].commentaire
          proj_remarque.value = response.data.lst[0].remarque
        }
      })
      .catch((err) => {
        msgErr.value = "Une erreur est survenue durant la lecture (projet) !";
        console.log(err);
      });
  } */