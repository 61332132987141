
import { defineComponent, ref, onMounted, TrackOpTypes } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { IUser } from "../../interfaces/user";
import { IProjet, IWork_lines } from "../../interfaces/project";
import { FormatDate } from "../../utils/functions";
import { useRouter } from "vue-router";
import avancement from "../../components/avancement.vue";
import Chart from "chart.js/auto";
import panel from "../../components/Panel.vue"
import suivi from "../../components/Suivi.vue"
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "ListeProject",
  components: {
    HeadBar,
    avancement,
    panel,
    suivi,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { cookies } = useCookies();

    let lstProjet = ref<IProjet[]>([]);
    let lstUser = ref<IUser[]>([]);
    let lstLines = ref<IWork_lines[]>([]);
    let lstPlanning = ref<any>([]);
    let msgErr = ref("");
    let msgSuccess = ref("");
    let affDetailProj = ref(-1);
    let modeAff = ref("no");
    const FD = FormatDate;
    let idUser = ref("All");
    let Line_progress = ref(0);
    let tabPourc = ref<number[]>([0, 0, 0, 0, 0]);
    let tabDelai = ref<any>([0, 0, 0, 0]);
    let myChart1: any;
    let myChart2: any;
    let idApercu = ref('')
    let idSuivi = ref('')
    let scroll1 = ref(0)
    let codeEnt = cookies.get('jwtAtomProgress_codeEnt')
    let token = cookies.get('AtomProgress_JWT')
    let includeFinish = ref(false)
    let tps = ref<number[]>([0,0,0,0])
    let line_tps = ref(0)

    onMounted(() => {
      readProjects(false);
      ListUsers();
      HeadBar.nbProjets = 500

      

    });

    const ListUsers = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          lstUser.value = response.data.lst;
          //console.log(lstUser.value);
        })
        .catch((err) => {
          msgErr.value =
            "Impossible d'afficher les données, votre session a peut être expirée !";
          console.log(err);
        });
    };

    const totalTps = () => {
      tps.value[0] = 0
      tps.value[1] = 0
      tps.value[2] = 0

      for (let i = 0; i<lstPlanning.value.length; i++) {

        tps.value[0] += parseFloat(lstPlanning.value[i].tpsPrevu.toString())
        tps.value[1] += parseFloat(lstPlanning.value[i].tpsReel.toString())

      }

      tps.value[2] = parseFloat(tps.value[0].toString()) - parseFloat(tps.value[1].toString())

    }

    const readProjects = (Actualise: boolean) => {

      if (Actualise) {
        myChart1.destroy();
        myChart2.destroy();
        tabPourc.value = [0,0,0,0,0]
        tabDelai.value = [0,0,0,0]
      }

      let finish = '0'
      if (includeFinish.value == true) {
        finish = '1'
      } else {
        finish = '0'
      }

      axios({
        method: "get",
        url: store.state.server_url + "/api/planning",
        params: {
          iduser: idUser.value,
          codeEnt: codeEnt,
          finish: finish
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          lstPlanning.value = [];
          if (response.data.lst != []) {
            lstProjet.value = response.data.lstProjet;
            //tri du tableau des Projets
            lstProjet.value.sort(function (a, b) {
              if (a.date_liv < b.date_liv) return -1;
              if (a.date_liv > b.date_liv) return 1;
              return 0;
            });
            lstLines.value = response.data.lstLines;
            lstLines.value.sort(function (a, b) {
              if (a.dateLivLigne < b.dateLivLigne) return -1;
              if (a.dateLivLigne > b.dateLivLigne) return 1;
              return 0;
            });

            let D1 = new Date();
            let totProgress = 0
            let nbL = 0
            for (let i = 0; i < lstLines.value.length; i++) {
              let idL: string = lstLines.value[i].idproject.toString();
              
              for (let j = 0; j < lstProjet.value.length; j++) {
                let Proj = lstProjet.value[j];
                let idP: string = Proj._id.toString();
                if (idL === idP && lstProjet.value[j].termine != true) {
                  
                  totProgress += parseInt(lstLines.value[i].progress.toString())
                  nbL++

                  let delai = CalcNbJours(
                    D1,
                    new Date(lstLines.value[i].dateLivLigne.toString())
                  );

                  let prevu = lstLines.value[i].tpsPrevu
                  if (prevu == undefined) prevu = 0

                  let reel = lstLines.value[i].tpsReel
                  if (reel == undefined) reel = 0

                  let Planning: any = {
                    _id: lstLines.value[i]._id,
                    designation: lstLines.value[i].designation,
                    commentaire: lstLines.value[i].commentaire,
                    progress: lstLines.value[i].progress,
                    projet: Proj,
                    nbJours: delai,
                    tpsPrevu: prevu,
                    tpsReel: reel,
                    deadLine: lstLines.value[i].dateLivLigne
                  };
                  lstPlanning.value.push(Planning);

                  //remplissage des tableaux pour les graphiques
                  

                  if (delai < 1) {
                    tabDelai.value[0]++;
                  } else {
                    if (delai < 6) {
                      tabDelai.value[1]++;
                    } else {
                      if (delai < 11) {
                        tabDelai.value[2]++;
                      } else {
                        tabDelai.value[3]++;
                      }
                    }
                  }
                }
              }
            }

            console.log(totProgress, nbL);
            

            if (totProgress > 0) totProgress = totProgress / nbL
            tps.value[3] = parseFloat(totProgress.toFixed(2))

            LoadTabPourc()
            console.log('tab1: ' + tabPourc.value);
            creeGraphNb1(false);
            creeGraphNb2();
            totalTps()
          }
        })
        .catch((err) => {
          msgErr.value =
            "Une erreur est survenue durant la lecture (projets) ! votre session a peut être expirée !";
          console.log(err);
        });
    };

    const LoadTabPourc = () => {
      tabPourc.value = [0,0,0,0,0]
      for (let j = 0; j < lstPlanning.value.length; j++) {
        switch (lstPlanning.value[j].progress) {
          case 0:
            tabPourc.value[0]++;
            break;
          case 20:
            tabPourc.value[1]++;
            break;
          case 40:
            tabPourc.value[2]++;
            break;
          case 60:
            tabPourc.value[3]++;
            break;
          case 80:
            tabPourc.value[4]++;
            break;
        }
      }
      console.log('tab2: ' + tabPourc.value);
    };

    const CalcNbJours = (d1: Date, d2: Date): number => {
      var Diff_temps = d2.getTime() - d1.getTime();
      var Diff_jours: number = Diff_temps / (1000 * 3600 * 24);
      return Math.round(Diff_jours);
    };

    const RecupUser = (id: string) => {
      let client = "";
      lstUser.value.forEach((element) => {
        if (element._id === id) {
          console.log(element._id, id);
          client = element.login + " (" + element.email + ")";
        }
      });
      return client;
    };

    const ModifProgress = (id: string) => {
      msgErr.value = "";
      axios({
        method: "patch",
        url: store.state.server_url + "/api/planning/progress",
        data: {
          id: id,
          progress: Line_progress.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          let l: number = lstPlanning.value.findIndex(
            (el: any) => el._id == id
          );
          if (l != -1) {
            lstPlanning.value[l].progress = Line_progress.value
            /* setTimeout(() => {
              LoadTabPourc()
                setTimeout(() => {
                  creeGraphNb1(true)
                }, 200);
            }, 200); */
                                    
          }
        })
        .catch((err) => {
          msgErr.value =
            "Erreur durant la mise à jour du pourcentage de progression !";
          console.log(err);
        });
    };

    const ModifTpsReel = (id: string) => {
      msgErr.value = "";
      //console.log(id, line_tps.value);
      let l: number = lstPlanning.value.findIndex(
        (el: any) => el._id == id
      );

      let reel = lstPlanning.value[l].tpsReel

      axios({
        method: "patch",
        url: store.state.server_url + "/api/planning/tps",
        data: {
          id: id,
          tps: reel,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          totalTps()
          /* if (l != -1) {
            lstPlanning.value[l].tpsReel = line_tps.value
                                    
          } */
        })
        .catch((err) => {
          msgErr.value =
            "Erreur durant la mise à jour du temps réel !";
          console.log(err);
        });
    };

    const creeGraphNb1 = (destroyBefore: boolean) => {
      //console.log('creeGraphNb1: ' + var1, var2,this.lstDevis1);
      console.log('tab3: ' + tabPourc.value);
      if (destroyBefore) myChart1.destroy();

      var ctx = document.getElementById("myChart1") as HTMLCanvasElement;

      if (ctx) {
        myChart1 = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: ["Pas démarré", "20 %", "40%", "60%", "80%"],
            datasets: [
              {
                label: "Commande / Devis / Facture",
                data: [
                  tabPourc.value[0],
                  tabPourc.value[1],
                  tabPourc.value[2],
                  tabPourc.value[3],
                  tabPourc.value[4],
                ],
                backgroundColor: [
                  "#404040",
                  "tomato",
                  "orange",
                  "yellow",
                  "#c7fdce",
                ],
                borderColor: ["red", "red", "darkorange", "yellow", "palegreen"],
                borderWidth: 1,
                
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    };
    const creeGraphNb2 = () => {
      //console.log('creeGraphNb1: ' + var1, var2,this.lstDevis1);
      var ctx = document.getElementById("myChart2") as HTMLCanvasElement;
      if (ctx) {
        myChart2 = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: [
              "Dépassé",
              "Reste - de 5 jours",
              "Reste - de 10 jours",
              "OK",
            ],
            datasets: [
              {
                data: [
                  tabDelai.value[0],
                  tabDelai.value[1],
                  tabDelai.value[2],
                  tabDelai.value[3],
                ],
                backgroundColor: ["tomato", "orange", "yellow", "palegreen"],
                borderColor: ["red", "darkorange", "darkorange", "green"],
                borderWidth: 1,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    };

    const AffArbo = (id: string) => {
      store.commit('change_IdArboProject',id)
        //console.log(id,store.state.current_project)
        store.commit('changeRetourListe', 'liste')
        router.push('/ArboProject')
    }


    const ApercuProj = (id: string, line: string, proj: string) => {
      let topS = parseInt(window.scrollY.toString()) //document.getElementById(line)
      console.log(topS);
      
      let top=0
      if (topS) {
        top = topS + 120
      } else {
        top = 120
      }
      idApercu.value = id
      const apercu = document.getElementById('apercu')
      if (apercu) {
        store.commit('changeApercuProj', [id, '-1', proj])
        apercu.style.opacity = "1"
        apercu.style.top = top + 'px'
        //apercu.style.left = '0px'
        //apercu.style.right = '0px'

      }
    }

    const FermeApercuProj = (id: string, user: string, proj: any) => {
      const apercu = document.getElementById('apercu')
      if (apercu) {
        //apercu.style.right = '-1000px'
        apercu.style.opacity = "0"
        apercu.style.top = "-440px"
      }
    }

    const AffSuivi = (id: string, WL: any) => {
      let topS = parseInt(window.scrollY.toString()) //document.getElementById(line)
      console.log(topS);
      
      let top=0
      if (topS) {
        top = topS + 120
      } else {
        top = 120
      }
      idSuivi.value = id
      const apercu = document.getElementById('apercu2')
      if (apercu) {
        store.commit('changeInfoSuivi', WL)
        apercu.style.opacity = "1"
        apercu.style.top = top + 'px'
        //apercu.style.left = '0px'
        //apercu.style.right = '0px'

      }
    }

    const FermeSuivi = (id: string, user: string, proj: any) => {
      const apercu = document.getElementById('apercu2')
      if (apercu) {
        //apercu.style.right = '-1000px'
        apercu.style.opacity = "0"
        apercu.style.top = "-440px"
      }
    }

    const ModifProj = (id: string) => {
      store.commit('changeCurrentProject',id)
      store.commit('changeRetourListe', 'todo')
      router.push('/CreateProject')
    }

    /* const OpenProject = (id: string) => {
        console.log(store.state.current_project)
        store.commit('changeCurrentProject',id)
        console.log(id,store.state.current_project)
        router.push('/CreateProject')
    }
*/

    return {
      lstProjet,
      msgErr,
      RecupUser,
      FD,
      //OpenProject,
      affDetailProj,
      modeAff,
      readProjects,
      //deleteProject,
      msgSuccess,
      lstUser,
      idUser,
      lstPlanning,
      Line_progress,
      ModifProgress,
      ApercuProj,
      FermeApercuProj,
      idApercu,
      idSuivi,
      ModifProj,
      includeFinish,
      AffSuivi,
      FermeSuivi,
      tps,
      line_tps,
      ModifTpsReel,
      AffArbo,
    };
  },
});
