
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "HeadBar",
  props: {
    nbProjets: Number,
    nbTermines: Number,
    loginActif: String,
    title: String,
  },

  setup(props) {
    const router = useRouter();
    const store = useStore();
    const { cookies } = useCookies();
    let IsAdmin = ref(false);

    const menuClick = () => {
      console.log("dans menuClick");

      const m = document.querySelector(".menuBtn");
      if (m) {
        m.classList.toggle("rotateMenu");
      }
      const m2 = document.querySelector(".menu");
      if (m2) {
        if (m2.classList.contains("affmenu") == true) {
          m2.classList.toggle("affmenu");
        } else {
          verifAdmin(m2);
        }
      }
    };

    const OpenUsers = () => {
      router.push("/CreateUser");
    };

    const OpenProjects = () => {
      store.commit("changeCurrentProject", "");
      router.push("/CreateProject");
    };

    const OpenListeProject = () => {
      router.push("/ListeProject");
    };

    const OpenQuestions = () => {
      router.push("/ListeQuestions");
    };

    const OpenPlanning = () => {
      router.push("/Planning");
    };

    const OpenToDo = () => {
      router.push("/ToDo");
    };

    const OpenHome = () => {
      router.push("/home");
    };

    const Deconnect = async () => {
      axios({
        method: "get",
        url: store.state.server_url + "/deconnect",
      })
        .then(() => {
          console.log("user deconnected");
          cookies.set('AtomProgress_JWT','',1)
          cookies.set('jwtAtomProgress_codeEnt','',1)
          cookies.set('jwtAtomProgress_UserId','',1)
          cookies.set('jwtAtomProgress_UserAdm','',1)
          cookies.set('jwtAtomProgress_UserPseudo','',1)
        })
        .catch((err) => {
          console.log(err);
        });
      router.push("/");
    };

    const verifAdmin = async (elem: any) => {
      axios({
        method: "get",
        url: store.state.server_url + "/verifAdmin",
        params: {
          login: cookies.get("jwtAtomProgress_UserPseudo"),
          codeEnt: cookies.get("jwtAtomProgress_codeEnt"),
        },
      })
        .then((response) => {
          console.log(response.data.admin);
          IsAdmin.value = response.data.admin;
        })
        .catch((err) => {
          console.log(err);
          IsAdmin.value = false;
        });
      elem.classList.toggle("affmenu");
    };

    const OuvrePortfolio = () => {
      window.open('http://atomdev-portfolio2022.s3-website.eu-west-3.amazonaws.com/', '_blank')  
    }

    return {
      menuClick,
      OpenUsers,
      Deconnect,
      OpenProjects,
      OpenListeProject,
      OpenQuestions,
      OpenHome,
      IsAdmin,
      verifAdmin,
      OpenPlanning,
      OuvrePortfolio,
      OpenToDo,
    };
  },
});
