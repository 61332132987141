
import { defineComponent, onMounted, ref, onUpdated } from "vue";

export default defineComponent({
  name: "avancement",
  props: {
    pourc: {
      type: Number,
      required: true,
    },
    idtemp: {
      type: String,
      required: true,
    },
  },

  setup(props) {

    let txt = ref('')

    onMounted(() => {
      TraceBarre();

      window.addEventListener("resize", () => {
        TraceBarre();
      });
    });

    onUpdated(() => {
      TraceBarre();
    })

    const TraceBarre = () => {
      if (props.pourc >= 0) {
        var p1: number = parseInt(props.pourc.toString());

        if (p1 > 100) {
          p1 = 100;
        }
        if (p1 < 0) {
          p1 = 0;
        }

        const barre = document.getElementById("barreAv" + props.idtemp);
        if (barre) {
          const lng: number = barre.offsetWidth;

          let p2: number = (p1 * lng) / 100;

          //console.log("lng:", lng, p2, props.idtemp);

          if (p2 >= 0) {
            const IdPourc: string = props.idtemp.toString();
            const p3 = document.getElementById(IdPourc);
            if (p3) {
              p3.style.width = p2.toString() + "px";
              switch (true) {
                case p1 < 25:
                  p3.style.backgroundColor = "tomato";
                  break;
                case p1 >= 25 && p1 < 50:
                  p3.style.backgroundColor = "orange";
                  break;
                case p1 >= 50 && p1 < 75:
                  p3.style.backgroundColor = "yellow";
                  break;
                case p1 >= 75 && p1 < 100:
                  p3.style.backgroundColor = "#c7fdce";
                  break;
                case p1 == 100:
                  p3.style.backgroundColor = "palegreen";
                  break;
              }
            }
          }
        }
      }
    };

    return {
      props,
      txt,
    };
  },
});
