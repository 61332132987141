
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import HeadBar from "@/components/HeadBar.vue";
import axios from "axios";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "CreateUser",
  components: {
    HeadBar,
  },
  setup() {
    const store = useStore();
    const { cookies } = useCookies();

    let nbProjet = ref(0);
    let nbTermine = ref(0);

    let login = ref("");
    let mdp = ref("");
    let email = ref("");
    let infos = ref("");
    //let searchEmail = ref("");
    //let searchLogin = ref("");
    let liste = ref<string[] | number[]>([]);
    let msgConfirm = ref("");
    let codeEnt = cookies.get('jwtAtomProgress_codeEnt')
    let token = cookies.get('AtomProgress_JWT')
    let modeCreate = ref(false)

    let msgErr = ref("");
    let msgSuccess = ref("");

    onMounted(() => {
      //read all the users
      axios({
        method: "get",
        url: store.state.server_url + "/api/users/all",
        params: {
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          console.log(codeEnt)
          liste.value = response.data.lst;
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });
    });

    const createUser = async () => {
      msgErr.value = "";
      msgSuccess.value = "";

      if (login.value === "" || email.value === "" || mdp.value === "") {
        msgErr.value =
          "L'Email, le login et le mot de passe sont obligatoires.";
        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/users",
        data: {
          email: email.value,
          login: login.value,
          mdp: mdp.value,
          infos: infos.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then(() => {
          msgSuccess.value = "Utilisateur créé avec succés";
          login.value = "";
          email.value = "";
          mdp.value = "";
          infos.value = "";

          setTimeout(() => {
            msgSuccess.value = "";
            modeCreate.value = false
          }, 1500);
        })
        .catch((err) => {
          msgErr.value = "Une erreur est survenue durant l'enregistrement !";
          console.log(err);
        });
    };

    return {
      nbProjet,
      nbTermine,
      login,
      mdp,
      email,
      infos,
      store,
      createUser,
      msgErr,
      msgSuccess,
      liste,
      msgConfirm,
      modeCreate,
    };
  },
});
