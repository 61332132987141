
import { defineComponent, ref, onMounted } from "vue";
import HeadBar from "@/components/HeadBar.vue"; // @ is an alias to /src
import Panel from "@/components/Panel.vue";
import { useCookies } from "vue3-cookies";
import axios from 'axios'
import { useStore} from 'vuex'
import { IProjet } from "../interfaces/project";
import { useRouter } from "vue-router";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "Home",
  components: {
    HeadBar,
    Panel,
  },
  setup() {
    let nbProjet = ref(0);
    let nbTermine = ref(0);
    let logActif = ref('')
    let iduser = ref('')
    let modeAdmin = ref(false)
    let modeAff = ref('no')
    let userType = ref('')
    let lstProjet = ref<IProjet[]>([])
    let msgErr = ref('')
    let codeEnt = ref('')
    
    
    const router = useRouter()

    const { cookies } = useCookies();
    const store = useStore()
    let token = cookies.get('AtomProgress_JWT')

    onMounted(async () => {
      
      logActif.value = cookies.get('jwtAtomProgress_UserPseudo')
      iduser.value = cookies.get('jwtAtomProgress_UserId')
      codeEnt.value = cookies.get('jwtAtomProgress_codeEnt')
      if (iduser.value != '') {
        //console.log('user: ' + iduser.value);
        axios({
        method: "get",
        url: store.state.server_url + "/verifAdmin",
         params: {
          login: logActif.value,
          codeEnt: codeEnt.value
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          
          if (response.data.admin == true) {
            userType.value = "All"
            modeAdmin.value=true
          } else {
            userType.value=iduser.value
          }
          
          ListProjects(userType.value)  
        })
        .catch((err) => {
          msgErr.value = "Votre session a expirer, vous devez vous reconnecter"
        });
              

      }

      //console.log(logActif.value) 
    })

    const ListProjects = async (id: string) => {

      await axios({
        method: "get",
        url: store.state.server_url + "/api/project/all",
        withCredentials: true,
        params: {
          iduser: id,
          search: modeAff.value,
          codeEnt: codeEnt.value
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          if (response.data.lst != []) {
            lstProjet.value = response.data.lst
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.msg == 'NO TOKEN FOUND') {
            msgErr.value = "Votre session a expirer, vous devez vous reconnecter"
          }
        });
    };

    const Deconnexion = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/deconnect",
      })
        .then((response) => {
          console.log("user deconnected");
        })
        .catch((err) => {
          console.log(err);
        });
      router.push("/");
    }

    

    return {
      nbProjet,
      nbTermine,
      logActif,
      lstProjet,
      modeAdmin,
      modeAff,
      userType,
      ListProjects,
      msgErr,
      Deconnexion,
    };
  },
});
