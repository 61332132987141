import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00bd6cf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["id"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "barreAv",
    id: 'barreAv' + _ctx.props.idtemp
  }, [
    (_ctx.props.pourc == 0)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Pas encore démarré"))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "pourc",
      id: _ctx.props.idtemp
    }, [
      (_ctx.props.pourc > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.props.pourc) + " %", 1))
        : _createCommentVNode("", true)
    ], 8, _hoisted_3)
  ], 8, _hoisted_1))
}