
import { defineComponent, ref, onMounted } from "vue";
import { IWork_lines, ISuivi } from "../interfaces/project";
import { FormatDate } from "../utils/functions";
import axios from "axios";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

axios.defaults.withCredentials = true;

export default defineComponent({
  name: "suivi",

  setup(props) {
    let store = useStore();
    const { cookies } = useCookies();

    let dateSuivi = ref(new Date());
    let texte = ref("");
    let enCreation = ref(false);
    let listSuivi = ref<ISuivi[]>([]);
    let FD = FormatDate;
    let msgErr = ref("");
    let msgSuccess = ref("");
    let codeEnt = cookies.get("jwtAtomProgress_codeEnt");
    let idWL = ""
    let idproject = ""
    let WL = ref('')
    let token = cookies.get('AtomProgress_JWT')


    onMounted(() => {
        if (store.state.infoSuivi.projet != undefined) {
          console.log(store.state.infoSuivi,store.state.infoSuivi.designation)
          idWL = store.state.infoSuivi._id
          idproject = store.state.infoSuivi.projet._id
          WL.value = store.state.infoSuivi.designation
        ReadSuivi()
        }
    })

    const CreateSuivi = () => {
      if (texte.value == "" || texte.value == undefined) {
        msgErr.value = "Le travail effecuté est obligatoire";
        return;
      }

      axios({
        method: "post",
        url: store.state.server_url + "/api/suivi",
        data: {
          id: idWL,
          idproject: idproject,
          dateSuivi: dateSuivi.value,
          texte: texte.value,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          msgSuccess.value = "Enregistrement terminé avec succés";
          setTimeout(() => {
            msgSuccess.value = "";
            enCreation.value = false;
            ReadSuivi()
          }, 1500);
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des utilisateurs !";
          console.log(err);
        });
    };

    const ReadSuivi = () => {
      axios({
        method: "get",
        url: store.state.server_url + "/api/suivi",
        params: {
          id: idWL,
          idproject: idproject,
          codeEnt: codeEnt,
        },
        headers: {
          'authorization': token
        }
      })
        .then((response) => {
          //console.log(response.data.lst)
          listSuivi.value = response.data.lst;
        })
        .catch((err) => {
          msgErr.value = "Impossible de charger la liste des suivis !";
          console.log(err);
        });
    };

    return {
      dateSuivi,
      texte,
      enCreation,
      listSuivi,
      FD,
      msgErr,
      msgSuccess,
      ReadSuivi,
      CreateSuivi,
      WL,

    };
  },
});
